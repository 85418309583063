<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 230px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                        width="55" label="#" >
      </el-table-column>
      <el-table-column prop="address" label="平台" >
        <template #default="scope">
          <span v-if="scope.row.platform==0">客户端</span>
          <span v-if="scope.row.platform==1">师傅端</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="系统" >
        <template #default="scope">
          <span v-if="scope.row.store==0">ios</span>
          <span v-if="scope.row.store==1">安卓</span>
        </template>
      </el-table-column>
      <el-table-column prop="version_code" label="编译版本" >
      </el-table-column>
      <el-table-column prop="version_name" label="版本名称" >
      </el-table-column>
      <el-table-column prop="address" label="操作" width="110">
        <template #header>
          <el-button size="mini"  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
        </template>
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
              <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">
        <el-form-item label="平台" prop="platform">
          <el-select  style="width:100%" v-model="ruleForm.platform" >
            <el-option
                v-for="item in platformArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="系统" prop="store">
          <el-select  style="width:100%" v-model="ruleForm.store" >
            <el-option
                v-for="item in storeArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="编译版本" prop="version_code">
          <el-input v-model="ruleForm.version_code"></el-input>
        </el-form-item>
        <el-form-item label="版本名称" prop="version_name">
          <el-input v-model="ruleForm.version_name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/Api"//本页面接口
export default {
  name: 'brand',
  data(){
    return{
      login:false,
      drawer:false,
      tableData:[],

      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        platform:0,
        store:0,
        version_code:'',
        version_name:'',
      },
      platformArr:[
        {
          id:0,
          name:'客户端',
        },
        {
          id:1,
          name:'师傅端',
        },
      ],
      storeArr:[
        {
          id:0,
          name:'ios',
        },
        {
          id:1,
          name:'安卓',
        },
      ],
      rules:{
        platform: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        store: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        version_code: [
          { required: true, message: '请填写', trigger: 'change' },
        ],
        version_name: [
          { required: true, message: '请填写', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.keyupSubmit()
  },

  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
      axios.get(form,'/api/admin/version_audit?w_type=all').then((res)=>{
        console.log(res)
        this.tableData=res.data.data
        console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        platform:0,
        store:0,
        version_code:'',
        version_name:'',
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.platform=row.platform
      this.ruleForm.store=row.store
      this.ruleForm.version_code=row.version_code
      this.ruleForm.version_name=row.version_name
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.post(this.ruleForm,'/api/admin/version_audit/add').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.post(this.ruleForm,'/api/admin/version_audit/edit').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.post({id:row.id},'/api/admin/version_audit/del').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
</style>